export default defineNuxtRouteMiddleware(async (to) => {
  if (process.browser) {
    const token = sessionStorage.getItem('googleAuthToken')
    if (!token) {
      return navigateTo('/admin/login' + '?redirectUrl=' + to.fullPath)
    }

    const { error } = await useHFetch('/google/verify', {
      method: 'POST',
      body: {
        token
      }
    })

    if (error.value) {
      return navigateTo('/admin/login' + '?redirectUrl=' + to.fullPath)
    }
  }
})
